<template>
    <div>
        <div class="banner_block">
            <div class="img_left">
                <img width="1000" height="487" src="~/assets/tennis_bracelet_banner/stud.webp" alt="model">
            </div>

            <div class="mobile_ver">

                <div class="img_left_mob">
                   <img width="1000" height="487" src="~/assets/tennis_bracelet_banner/stud.webp" alt="model">
                </div>

                <div class="img_right_mob text-center">
                    <picture>
                        <source media="(max-width: 992px)" width="1000" height="487" type="image/webp" srcset="~/assets/tennis_bracelet_banner/bracelet.webp">
                        <img width="1000" height="487" type="image/webp" src="~/assets/tennis_bracelet_banner/bracelet.webp"
                        alt="bracelet">
                    </picture>
                <!-- <img width="1174" height="540" src="~/assets/tennis_bracelet_banner/bracelet.png" alt="modal"> -->
            </div>

            </div>

            <div class="content text-center">
      
                    <h3>These pieces sold out 6 times!</h3>
                    <!-- <p>Tennis Bracelets starting as low as $1000 <br>shipped in just 5 days!</p> -->
                    <p>Our Best-Selling Diamond <span class="highlight">Stud Earrings</span> & <span class="highlight">Tennis Bracelets</span> are back! <br>
                        Grab yours now before they’re gone again! Ships in just 5 days.</p>
                    <NuxtLink
                        :to="localePath({ name:'shop-all', query:{ 'Style': 'Stud-earring,Tennis-bracelet','Shipdate': '8_days', 'refreshKeyTwo': 'all'} })"
                        >
                        <div class="button btn-s-animation">
                            <span>SHOP IN-STOCK PICKS</span>
                        </div>
                    </NuxtLink>
            </div>
       
            <div class="img_right text-center">
                    <picture>
                        <source media="(max-width: 992px)" width="1000" height="487" type="image/webp" srcset="~/assets/tennis_bracelet_banner/bracelet.webp">
                        <img width="1000" height="487" type="image/webp" src="~/assets/tennis_bracelet_banner/bracelet.webp"
                        alt="bracelet">
                    </picture>
                <!-- <img width="1174" height="540" src="~/assets/tennis_bracelet_banner/bracelet.png" alt="modal"> -->
            </div>
            


        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.banner_block {
    display: flex;
    /* background-image: url('/assets/free-product/bg_desktop.jpg'); */
    background: #F9F9F9;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.img_left {
    width: 23%;
}

.img_right {
    width: 23%;
}

.content {
    width: 40%;
}

.content h3 {
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 0;
    color: #405955;
    margin-bottom: 12px;
    text-transform: uppercase;
    /* margin-bottom: 16px; */
}

.content p {
    font-size: 16px;
    color: #606060;
    font-weight: 500;
    margin-bottom: 32px;
}
/* .content p br{
    display: none;
} */
.button {
    /* padding: 14px 30px; */
    border: 1.5px solid #000000;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 252px;
    max-width: 252px;
    margin: 0 auto;
    cursor: pointer;
}

.button span {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    letter-spacing: 1.5px;
}
.mobile_ver{
    display: none;
}
@media (max-width: 1770px) {
    .content h3{
        font-size: 28px;
    }
    .content p {
        font-size: 18px;
    }
    .content p br{
        display: block;
    }
    .button{
        margin-bottom: 5px;
    }
}
@media (max-width: 1440px) {
    .content h3{
        font-size: 24px;
        letter-spacing: 4px;
    }
    .content p {
        font-size: 18px;
    }
    .content p br{
        display: none;
    }
    .button{
        margin-bottom: 5px;
    }
}
@media (max-width: 1200px){
    .content h3{
        font-size: 20px;
        margin-bottom: 4px;
    }
    .content p {
        font-size: 16px;
        margin-bottom: 8px;
    }

}

@media(max-width:992px) {
    .banner_block {
        flex-direction: column;
        padding: 2% 0;
    }

    .img_left {
        display: none;
    }

    .img_right {
        display: none;
    }

    .content h3 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 8px;
    }

    .content p {
        font-size: 16px;
        margin: 12px 0;
    }

    .mobile_ver{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .img_left_mob , .img_right_mob{
        width: 45%;
    }

    .button {
        margin-bottom: 20px;
        /* padding: 7px 10px; */
        /* width: 40%; */
        height: 45px;
    }

    .content {
        width: 90%;
    }

}
@media (max-width : 767px){
    .content p {
        font-size: 14px;
        margin: 0 0 20px 0;
    }
    .highlight{
        font-weight: 700;
    }

}
</style>